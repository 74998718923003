.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.input_text {
  width: 300px;
  height: 35px;

  /* background: #FFFFFF; */
  /* box-shadow: inset 0px -1.53199px 15.3199px rgba(0, 0, 0, 0.11), inset 0px 1.53199px 15.3199px rgba(0, 0, 0, 0.11); */
  border-radius: 58.2155px;
}

.modalContainer {
  font-family: 'Gotham', sans-serif;
  width: auto;
  height: auto; /*370px*/
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  position: fixed;
  top: 0;
  margin-top: 7%;
  /* padding: 50px; */
  z-index: 1000;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 1.7rem;
  position: fixed;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: larger;
}
/* input[type='text'] {
  border-radius: 15px;
} */
.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}
.file-container {
  width: 150px;
  height: auto;
  padding: 1%;
  border: 1px solid rgba(0, 0, 0, 0.283);
  border-radius: 10%;
}
.selfi_title_box {
  display: flex;
  justify-content: center;
  font-size: 3vh !important;
}

@media (max-width: 970px) {
  .modalContainer {
    font-family: 'Gotham', sans-serif;
    width: min-content;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    position: fixed;
    top: 0;
    margin-top: 20%;
    /* padding: 50px; */
    z-index: 1000;
  }
}
.progress-bar{
      height: 20px;
}
