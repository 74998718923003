.logoDownList li{
    display: inline-block;
    margin: 5px;
}

.logoDownList li a{
    display: block;
    margin: 5px;
    padding: 5px 30px;
    text-decoration: none;
    border: 1px solid #ffd900;
    background-color: #ffd900;
    border-radius: 20px;
    color:#2b304e;
}