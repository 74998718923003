body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.title {
  word-break: normal;
}

.section,
.hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.column {
  flex-basis: unset;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-footer {
  margin-top: auto;
}

.redux-toastr .toastr .close-toastr {
  opacity: 1;
  color: #fff;
}

.redux-toastr .toastr .rrt-left-container {
  width: 75px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  width: 26px !important;
  height: 26px !important;
}

.redux-toastr .toastr .rrt-middle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 75px;
  min-height: 70px;
}

.error {
  color: red;
}

.error::before {
  display: inline;
  content: '⚠ ';
}

// section page css here =====================>>>>>>>>>>>>>>>>>>.

.header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  // line-height: 96px;
  /* identical to box height */

  color: #1ca63b;
}

.sub_header {
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 3vh;
  // line-height: 41px;
  /* identical to box height */
  text-align: start;
  color: rgba(142, 142, 142, 1);
}
.sub_heading {
  font-family: 'Gotham' !important;
  font-style: italic;
  font-weight: 500;
  font-size: 2vh;
  text-align: start;
  color: rgba(142, 142, 142, 1);
}
.flag_pin {
  display: flex;
  justify-content: start;
}
.landing_icon {
  // height: 20vh !important;
  min-width: 10vw !important;
}
.orange {
  color: rgba(255, 122, 0, 1);
}
.green {
  color: #1ca63b;
}
.topic-title {
  font-size: 5vh;
  text-align: start;
}

.topic-desc {
  font-size: 2vh;
}
.about-section {
  margin-top: 0% !important;
}
@media only screen and (min-width: 600px) {
  .spacing {
    padding: 4rem 0 5rem 0;
  }
  .main_heading {
    padding-top: 4rem;
  }
}

.spacing1 {
  padding: 5rem 0 5rem 0;
}

.pin_btn {
  width: 10rem;
  height: 81.12px;
}

.watch_more {
  width: 12rem;
  display: flex;
  align-items: initial;
}

.flag_play {
  position: absolute;
  border-radius: 16.2309px;
  width: 648px;
  height: 365px;
  z-index: 1000;
}

.video {
  height: 40vh;
  border-radius: 16.2309px;
  // z-index: -1000;
}

.vid_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  // line-height: 113px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3c2400;
}

.video_text {
  text-align: initial;
}

.galary_header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  padding-bottom: 10px;
  // line-height: 119px;
  /* identical to box height */

  // display: flex;
  // align-items: center;
  text-align: center;
  letter-spacing: -0.015em;

  color: #3c2400;
}

.flag_img {
  width: 20rem;
  // height: 675.22px;
  // display: flex;
  // align-items: center;
}

.flag_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  // line-height: 3.5rem;
  display: flex;
  align-items: center;
  color: #3c2400;
}

.flag_text {
  text-align: initial;
}
.hashtag-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  align-items: center;
  color: #3c2400;
  display: flex !important;
  justify-content: start;
  // margin-left: 8.5%;
  margin-bottom: 2%;
}
.blinking {
  animation: blinkingText 1.2s infinite;
}
.close_btn {
  cursor: pointer;
}
.notifier {
  position: absolute;
  bottom: 5% !important;
}
.selfi_counter {
  text-align: center;
}
.total_selfi {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 2rem;
  /* text-align: center; */
  letter-spacing: 0.015em;
  color: #30b6f4;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // height: 50%;
  height: auto;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.container:hover .overlay {
  opacity: 1;
}
.table_text {
  font-size: 1.8vh;
}

@keyframes blinkingText {
  0% {
    color: #10c018;
  }
  25% {
    color: #f56308;
  }
  50% {
    color: #10c018;
  }
  75% {
    color: #f56308;
  }
  100% {
    color: #10c018;
  }
}
.user_text_q {
  font-size: 2vh;
  font-weight: 500;
  text-align: initial;
  font-family: 'Inter', sans-serif;
}
.flag_video {
  display: flex;
  justify-content: center;
}
.sm_font {
  font-size: 1.5vh;
}
@media (max-width: 970px) {
  //(max-width: 480px)
  .flag_video {
    display: flex;
    justify-content: center;
  }
  .selfi_counter {
    text-align: center;
  }
  .user_text_q {
    font-weight: 500;
    text-align: center !important;
    font-family: 'Inter', sans-serif;
  }
  .landing_icon {
    height: 18vh !important;
    width: auto;
  }
  .video {
    height: 25vh !important;
  }
  .about_btn {
    display: flex;
    justify-content: center !important;
  }
  .flag_link {
    text-align: center;
    // margin-left: 25%;
  }

  .vid_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 66px;
    display: contents;
    align-items: center;
    text-align: center;
    color: #3c2400;
    // margin: 50px 0 50px 0;
  }

  .video_text {
    text-align: center;
  }

  .flag_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 66px;
    display: contents;
    align-items: center;

    color: #3c2400;
  }
  .hashtag-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    align-items: center;
    color: #3c2400;
    display: contents !important;
    justify-content: end;
  }

  .flag_text {
    text-align: center;
  }
  .about {
    text-align: center;
  }
  .sub_header {
    font-family: 'Gotham' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 2.2vh;
    line-height: 41px;
    /* identical to box height */
    text-align: center !important;
    color: rgba(142, 142, 142, 1);
  }
  .sub_heading {
    font-family: 'Gotham' !important;
    font-style: italic;
    font-weight: 500;
    font-size: 1.8vh;
    text-align: center !important;
    color: rgba(142, 142, 142, 1);
  }
  .flag_pin {
    display: flex;
    justify-content: center;
  }
  .topic-title {
    font-size: 4vh;
    text-align: center !important;
    display: flex;
    justify-content: center;
  }
  .hashtag-div {
    display: flex;
    justify-content: center !important;
  }
  .topic-desc {
    font-size: 2vh;
  }
  // .about-section {
  //   margin-top: 40% !important;
  // }

  .selfi-row {
    display: contents !important;
  }
  .selfie-cont {
    display: flex;
    // width: 332px !important;
    overflow-x: scroll !important;
  }
  .tweet-cont {
    display: flex;
    // width: 332px !important; //332px
    overflow-x: scroll !important;
  }

  .about {
    text-align: center;
    margin-left: 0% !important;
  }
  .blog-content {
    overflow-x: auto !important;
    overflow-y: hidden;
    flex-wrap: nowrap !important;
  }
  .date {
    display: flex;
    justify-content: center !important;
  }
  .footer_text {
    font-size: 1.2vh;
    font-weight: bolder;
  }
}
.date {
  display: flex;
  justify-content: start;
}
/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #10bd36b7;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.selfi-row {
  display: contents !important;
}
.self-row {
  display: block;
}
.selfie-cont {
  overflow-x: hidden;
}
// .tweet-cont {
//   overflow-x: hidden;
// }

.tweet-cont {
  display: flex;
  // width: 332px !important;
  overflow-x: auto;
}

.about {
  text-align: center;
  // margin-left: 7%;
}

.globe {
  width: 3rem;
}

.total_flag {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.015em;

  color: #30b6f4;
}
.name_text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.globe_text {
  font-size: 2vh;
  font-family: 'Gotham';
  font-style: normal;
  padding-top: 0;
  color: #353535;
}
.minLogo {
  max-width: 82px;
  max-height: 33px;
}
.logo {
  min-width: 57px;
  height: 35px;
}
.hashtagImg_sm {
  padding: 12px;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
}

.hashtagImg {
  padding: 12px;
  display: inline-block;
  // border: 2px solid hsl(39deg 54% 91%);
  border-radius: 5px;
  margin: 2%;
  // background-color: hsl(39deg 80% 95% / 72%);
  text-align: center;
}

.child {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 7rem;
}

.dropdown-toggle::after {
  display: contents !important;
}
.btn_pin {
  font-weight: 700;
  font-size: 2vh;
}
.btn_text {
  font-weight: 700;
  font-size: 2vh;
  color: #fff;
}
.white {
  color: #fff;
}
.btn_text_mal {
  font-weight: 700;
  font-size: 1.5vh;
}

// ----------LOGIN-----------------

.create_acc {
  padding: 15px 0 15px 0;
}

.forget_password {
  text-align: end !important;
  font-family: 'Gotham';
}

.login {
  background-color: #1ca63b;
  border: none;
  color: #fff;
  min-width: 200px;
  border-radius: 20px;
  width: 150px;
  padding: 10px;
  text-align: center;
}
.login-title {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 40px;
  padding: 0.75rem;
  font-weight: 400;
  color: #333333;
}

.gm-style-iw-t {
  // display: none;
}
.gm-style-iw-t:active {
  display: block;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.blog-content {
  flex-wrap: wrap;
  overflow-x: hidden;
}

.cst-attrib-cont {
  outline: none;
  position: absolute;
  width: 100%;
  white-space: nowrap;
  bottom: 8% !important;
}

.navbar-menu {
  overflow: visible !important;
}
.dropdown-menu {
  height: auto;
  overflow: auto;
  max-height: 40vh;
}
hr.org {
  border-top: 4px solid #ff6301;
  opacity: inherit;
  margin: 0.25rem 0 !important;
}
hr.grn {
  border-top: 4px solid #229e3d;
  opacity: inherit;
  margin: 0.25rem 0 !important;
}
.footer_text {
  font-size: 2vh;
  font-weight: bolder;
}
.filter_img {
  border-radius: 10px;
  box-shadow: #333333;
}
.display_hide {
  display: none !important;
}
